:root {
    --primary: #1C4076;
    --dark: #202020;
}
select.form-control:not([size]):not([multiple]){
    height:auto !important;
}
#preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
}

#preloader-status {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    margin: -100px 0 0 -100px;
    /*You can use animated gif for preloader*/
    /*background: url('../img/preloader.png') no-repeat 50% 20px;*/
}

#preloader-title {
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
}

.spinner {
    margin: 0 auto;
    width: 120px;
    height: 30px;
    text-align: center;
    font-size: 10px;
}

    .spinner > div {
        background-color: var(--primary);
        height: 100%;
        width: 20px;
        margin: 1px;
        display: inline-block;
        -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
        animation: stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

@-webkit-keyframes stretchdelay {

    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
    }

    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes stretchdelay {

    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}
.main-nav.home-nav .navbar .nav-link {
    color: #fff;
}
.main-nav.main-nav.home-nav .navbar-toggler span {
    background-color: #fff;
}
.main-nav .navbar-brand .site-title-kh {
	font-size: 14px;
}
.main-nav .navbar-brand .title-en {
	text-transform: none;
	font-size: 13.5px;
}
/*.nav-open .main-nav.main-nav.home-nav .navbar-toggler span {
    background-color: var(--primary);
} */
.dropdown-item {
    cursor: pointer;
}

.main-nav.home-nav .dropdown-menu {
    background-color: var(--primary);
    margin-top: 0;
}
.main-nav.home-nav .dropdown-menu .dropdown-item {
    color: #fff;
}
.main-nav.home-nav .dropdown-menu .dropdown-item:hover {
    color: var(--primary);
}

.nav-item:hover .dropdown-menu {
    display: block;
}
.dropdown-item:hover {
    color: var(--dark);
}
/* a:hover {
    text-decoration: none;
} */

/*
 set viewport to 100% height
 */
html {
    height: 100%;
    scroll-behavior: smooth;
}
/* khmer unicode break on firefox*/
.word-break-all {
    word-break: break-word;
}

body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
body.nav-open {
    overflow: hidden;
}
body.nav-open .navbar-toggler {
    position: absolute;
    top: 1rem;
    left: 1rem;
}
body.nav-open  .navbar-toggler span {
    background-color: var(--primary) !important;
}
body.nav-open .form-inline > .navbar-toggler {
    display: none;
}
.body-section {
    margin-top: 30px;
    padding-bottom: 30px;
}
.home-page .domain-type .type {
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.home-page .domain-type .type .type-item-list {
    margin-top: auto;
}
.home-page .faq-container {
    background-color: transparent;
}
/* p, ul, label {
    word-break: break-word;
} */
.banner figure figcaption .group-search .dropdown-menu {
    transform: translate3d(-14px, 42px, 0px) !important;
}
.group-search .dropdown-menu {
    transform: translate3d(-18px, 42px, 0px) !important;
}



/* Mobile header */
@media (max-width: 992px) {
    .main-nav nav {
        justify-content: start;
    }
    .main-nav .form-inline .list-inline-item {
        margin: 0 !important;
    }
    .main-nav .navbar-toggler {
        z-index: 999;
    }
    .main-nav .navbar ul.list-inline .list-inline-item:last-child {
        border-left: 1px solid #c4c4c4 !important;
    }
    body .mobile-nav {
        z-index: 99;
        height: 100vh;
        width: 60%;
        overflow-y: auto;
    }
    body.nav-open .mobile-sign .group-log a {
        background-color: #fe2a2e;
        border: 1px solid #fe2a2e;
        padding: 0.5rem 1rem;
        height: 38px;
    }
    body .mobile-nav::before {
        content: none;
    }
    .main-nav .navbar .nav-item {
        margin-bottom: 15px; 
    }
}


/*
fixed custom containers not full and some spaces
*/
@media (min-width: 1500px) {
    .banner figure figcaption {
        width: 700px;
    }
    .purchase-page .container-body {
        max-width: 1360px;
    }
    .container-body {
        max-width: 1360px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .home-page .container-body.layout-1 {
        max-width: 1360px;
    }
    .container-body.manage-domain {
        max-width: 1360px;
    }
}

.container-body {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
}
.banner figure figcaption {
    position: static;
    padding-bottom: 50px;
}
.banner .main-container {
    transform: translate(0, -100%);
}
@media (min-width: 1920px) {
    .main-container {
        max-width: 1920px;
        min-width: 1600px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .banner .main-container {
        max-width: 1280px;
        min-width: 1280px;
    }
}
.banner figure,
.banner figure img {
    object-position: top;
    max-height: 600px;
}

.banner figure figcaption .group-search .form-control {
    scroll-margin-top: 460px;
}
.banner figure figcaption .group-search .form-control::-webkit-input-placeholder {
	color: #231f20;
}
.home-page .domain-type .type .type-description {
    font-size: 15px;
}

/*
 disabled scrollbar
  */
.custom-form select.form-control {
    appearance: none;
}

.dashboard table {
    margin-top: 28px;
}
@media (max-width: 575px) {
    .dashboard table thead tr th:nth-child(4),
    /* .dashboard table tbody tr td:nth-child(4) {
        display: none;
    } */
    .dashboard table.my-cart thead tr th:nth-child(3),
    .dashboard table.my-cart tbody tr td:nth-child(3) {
        width: 100px;
    }
    .dashboard table.my-cart thead tr th:nth-child(4),
    .dashboard table.my-cart tbody tr td:nth-child(4) {
        display: table-cell;
        width: 50px;
    }
    .dashboard table.my-cart .btn {
        display: inline-block;
    }
}

/* FAQ */
.faq-container .faq-collapse {
    display: flex;
    align-items: center;
}
.faq-collapse .faq-title {
    margin-right: 10px;
}
/*
Purchase Page
 */
.purchase-page .content-section .custom-form .card {
    padding: 24px 20px;
}
.purchase-page ol.breadcrumb li {
    position: relative;
    flex: 1;
}
@media (max-width: 767.98px) {
    .purchase-page ol.breadcrumb li::after {
        content: none !important;
    }
}
.purchase-page ol.breadcrumb li::after {
    content : '';
    height: 2px;
    background-color: #f3f3f3;
    width: 100%;
    position: absolute;
    top: 30px;
    left: 50%;
    z-index: -1;
}
.purchase-page ol.breadcrumb li a {
    display: grid;
    place-content: center;
    text-align: center;
}
.purchase-page ol.breadcrumb li a.active, .purchase-page ol.breadcrumb li.active a {
    border-bottom: none;
    pointer-events: none;
}
.purchase-page ol.breadcrumb li .active .circle, .purchase-page ol.breadcrumb li.active .circle {
    background: linear-gradient(0deg, rgba(22,52,123,1) 10%, rgba(45,141,255,1) 100%);
}
.purchase-page ol.breadcrumb li .circle {
    width: 60px;
    height: 60px;
    background: #fff;
    display: grid;
    place-content: center;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 2px solid #F3F3F3;
    margin-left: auto;
    margin-right: auto;
}


.purchase-page .custom-control input {
    width: 20px;
    height: 20px;
}    
.purchase-page .content-section .custom-form .card {
    border: 1px solid #f3f3f3;
    margin-bottom: 0;
}


.custom-form .form-group > .float-label {
    font-size: 14px;
}
.custom-form .form-group input.form-control:not(:placeholder-shown) ~ .float-label,
.custom-form .form-group input.form-control:focus ~ .float-label,
.custom-form .form-group textarea.form-control:not(:placeholder-shown) ~ .float-label,
.custom-form .form-group textarea.form-control:focus ~ .float-label,
.custom-form .form-group select.form-control ~ .float-label,
.custom-form .form-group input.form-control[type="date"] ~ .float-label {
    margin-top: -14px;
    z-index: 9;
}
/* .custom-form :has */
.custom-form .btn-groups {
    gap: 10px;
}

.custom-form .btn-groups .btn {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 575px) {
    .purchase-page .card .title {
        font-size: 16px;
    }
    .custom-form .btn-groups .btn {
        height: 30px;
        width: 30px;
    }
}
.custom-form .custom-file-input,
.custom-form .custom-file-label {
    height: calc(1.5em + 0.75rem + 17px);
    cursor: pointer;
}
.custom-form .custom-file-label {
    background-color: var(--primary);
}
/* .custom-form .form-group > .form-control,
.custom-form .form-group > .float-label, 
.custom-form .input-group > .form-control, 
.custom-form .input-group > .float-label,
.custom-form .form-control[type="date"] {
    padding: 10px 20px;
} */
.custom-form .custom-checkbox .custom-control-label::after {
    width: 1rem;
    height: 1rem;
    left: -1.5rem;
    border-radius: .25rem;
    top: .3rem;
}
.custom-form .form-group .form-control:disabled {
    background-color: #e9ecef;
}
.custom-form .form-group .form-control:disabled ~ .float-label {
    background: linear-gradient(to top,#e9ecef,#fff) !important;
    border: none;
}
.custom-form .form-group .helper,
.custom-form .input-group .helper {
    margin-left: 0;
}

/*
Custom Radio
*/
.purchase-page .custom-radio .form-check-input:checked,
.payment-section .custom-radio .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2316347B'/%3e%3c/svg%3e");
}
.purchase-page .custom-radio .form-check-input,
.payment-section .custom-radio .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid var(--primary);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    border-radius: 50%;
}

/* fixed validation */
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
    color: #212529 !important;
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label {
    background-color: #fe2a2e;
}
.custom-form .custom-file-label::after {
    content: none !important;
}
.was-validated :invalid ~ .btn-primary {
    border: 1px solid #fe2a2e;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem rgb(22 52 123 / 25%);
}

.dashboard table thead tr th {
    font-weight: 700;
}

/*
 Payment page
 */
.payment-section .content .payment .payment-option .custom-radio .custom-control-label::after {
    left: -2rem;
    background-size: 1rem;
}
.payment-section .content .payment .payment-option .custom-radio .custom-control-label::before {
    left: -1.9rem;
}
.payment-section .content {
    padding: 0;
}

/* Breadcrumb */
.breadcrumb .disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}


/*
 set footer to bottom screen
 */
.footer-grid {
    background-color: transparent;
    margin-top: auto;
}


@media (max-width: 575px) {
    .main-nav .navbar-brand img {
        height: 40px;
    }
    .main-nav .navbar-brand .site-title-kh {
        font-size: 12px;
    }
    .main-nav .navbar-brand .title-en {
        font-size: 11.6px;
    }
    .main-nav .navbar-brand img {
        max-width: none;
    }
    body .mobile-nav {
        width: 70%;
    }
    .home-page .domain-type .type {
        margin: 0;
    }
    .footer-grid ul .list-group-item {
        padding-top: 0;
    }
    .footer-grid ul .list-group-item a {
        padding-left: 0;
    }
}
.purchase-page .custom-control {
    align-items: center;
}

/* .purchase-page .btn {
    font-size: .9rem;
} */

/* Feedback Form */
.feedback {
	background-color: #EB7D3010;
}

@media (max-width: 992px) {
    .list-group-horizontal-md > .list-group-item + .list-group-item {
        padding-left: 0;
        padding-right: 0;
    }
    .main-nav.home-nav .mobile-nav a,
    .main-nav.home-nav .mobile-nav .nav-link {
        color: #231f20;
    }
}

@media (max-width: 1024px) {
    .banner figure,
    .banner figure img {
        max-height: 450px;
    }
}

@media (min-width: 992px) {
				.main-nav .navbar .nav-item {
						padding: 0 .8rem;
				}
    .content.column-2 {
        column-count: 2;
        column-rule: 1px solid #c4c4c4;
        column-gap: 39px;
    }

        .content.column-2 .property div {
            flex: 1;
        }

    /* .purchase-page .content-section {
        padding-top: 10px;
    } */
}
@media (max-width: 1199.98px) {
    .banner figure figcaption {
        max-width: 400px;
    }
    .banner figure, .banner figure img {
        max-height: 450px;
    }
    .home-page .domain-type .type .type-description {
        font-size: 14px;
    }
}