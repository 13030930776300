.payment-container {
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
}

.pay-method {
    position: relative;
}

.pay-method input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    left: 0;
    right: 0;
}
.pay-method input:checked ~ .payment-option {
    border-color: var(--payment-ui-color-primary) !important;
}
.pay-method input:checked ~ .payment-option .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2316347B'/%3e%3c/svg%3e");
}

.payment-ui {
    --payment-ui-color-primary: #16347B;
    --payment-ui-color-text-primary: #454545;
    --payment-ui-color-text-secondary: #999999;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #999999;
}

.payment-ui--item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    border-radius: 8px;
    transition: .2s ease;
    border: 1px solid transparent;
    margin: 2px 0;
    color: #999999;
}

.payment-ui--item-content {
    flex-grow: 1;
}

/*.payment-ui--item:hover,
.payment-ui--item:focus {
background-color: rgba(0,0, 0, .05);
border: 1px solid rgba(0,0,0, .08);
}

.payment-ui--item:active {
background-color: rgba(0, 0, 0, .1);
border: 1px solid rgba(0, 0, 0, .12);

}*/

.payment-ui--item-title {
    color: #014282;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.3;
}

.payment-ui--item-subtitle {
    margin-top: 4px;
    font-size: 0.825em;
    color: #4D4D4D;
    line-height: 1.3;
    font-weight: 400;
}

.payment-ui--logo {
    margin-right: 14px;
    display: grid;
    place-items: center;
}

.payment-ui--logo img {
    width: 5em;
    max-width: none;
}

.pay {
    color: transparent;
}

.aba-checkout-content iframe{
    max-height: 590px !important;
}

#aba_checkout_sheet .aba_checkout_contents {
    min-height: 210px !important;
}
