@import 'src/v2/scss/core';

.ant {
    &-modal {
        .ant-btn {
            padding: 10px 20px;
            font-size: $s18;
            border-radius: 10px;
            margin-left: 16px !important;
            font-weight: $semibold;
        }

        width: 920px !important;

        &.is-confirm {
            .ant-modal-header {
                height: 40px;
            }
        }

        &-content {
            border-radius: 40px;
            overflow: hidden;
        }

        &-close {
            top: 6px;
            right: 24px;
            color: #fff;

            &-x {
                @include flex-center;
                font-size: $s30;
            }
        }

        &-header {
            height: 60px;
            background: $orange;
            padding: 0;
        }

        &-title {
            @include flex-start-center;
            padding: 0 24px;
            height: 100%;
            font-size: $s25;
            color: #fff;

            &-close {
                width: 44px;
                height: 44px;
                @include flex-center;

                svg {
                    font-size: $s32;
                    color: #fff;
                }
            }
        }

        &-body {
            padding: 0;
            font-size: $s35;

            &-container {
                padding: 40px;
                @include flex-start-center;
            }

            &-icon {
                margin-right: 24px;

                svg {
                    font-size: $s35;
                }
            }

            &-msg {
                color: $blue;
            }
        }

        &-footer {
            padding: 12px 24px;
        }
    }
}

@include layout-bp('lt-xl') {
    .ant {
        &-modal {
            width: 100% !important;
            max-width: 520px;
            padding: 0 15px;

            .ant-btn {
                font-size: 16px;
                padding: 5px 15px;
            }

            &-close {
                top: 0;

                &-x {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 24px;
                }
            }


            &-content {
                border-radius: 20px;
            }

            &-title {
                font-size: 20px;
            }

            &-header {
                height: 40px;
            }

            &-body {
                font-size: 15px;

                &-container {
                    padding: 24px;
                }

                &-msg {
                    line-height: 25px;
                }

                svg {
                    font-size: $s24;
                }
            }
        }
    }
}
