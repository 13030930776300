:root {
  --primary: #1C4076;
  --dark: #202020;
  --orange: #FF7C00;
  --kantumruy: 'Kantumruy Pro', sans-serif;
  --poppins: 'Poppins', sans-serif;
  --fonts: 'Kantumruy Pro', 'Poppins', sans-serif;
  --blue: #014282;
  --green: #47BC03;
  --white: #ffff;
}

/*New style sheet*/

body {
  font-family: var(--fonts);
  background-color: #fff;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-primary);
} */

nav a,
ul a {
  color: var(--white);
  font-size: 1em;
  text-transform: inherit;
}

/* .page-title {
  font-size: 1.7em;
  color: #014282;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
}

@media (min-width: 1200px) {
  .page-title {
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
    margin: 60px 0;
  }
} */

.page-description {
  font-size: 1.25em;
  text-align: center;
  line-height: 1.5em;
}

.card.payment-card {
  border-top-left-radius: 1.5em !important;
  border-top-right-radius: 1.5em !important;
}

.card.payment-card .card-header {
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
  background-color: #014282;
  color: #fff;
}

.card.payment-card .card-header {
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
  background-color: #014282;
  color: #fff;
}

.card.payment-card .card-body {
  background-color: rgb(77, 77, 77, 0.05);
  padding-bottom: 2em;
}


.payment-page h4 {
  font-size: 1.5em;
}

.payment-page .caption-text,
.payment-page .body-text {
  font-size: inherit;
}

.payment-section .content .payment .payment-option {
  border: none;
}

@media (min-width: 992px) {
  .payment-section .content {
    flex-direction: column;
  }
}

/* .main-nav.home-nav {
  background: linear-gradient(-90deg, #007dc5 0%, #004282 100%);
}

.navbar-brand .site-head img {
  height: 100px;
}

.navbar .flag {
  width: 35px;
}

.navbar .navbar-nav .nav-link {
  font-size: 1em;
}

.corner-bottom {
  border-bottom-left-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
}

footer .footer-bg {
  background: linear-gradient(-90deg, #004282 0%, #004282 100%);
  color: #fff;
  padding: 1em 0;
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
}

footer .footer-bg a {
  color: #fff;
}

.footer-container .logos {
  padding: 1em 0;
}

.footer-container .logos img {
  width: 100px;
}

.footer-center {
  line-height: 2em;
}

.footer-socials input[type='email'] {
  height: 42px;
  border-radius: 24px;
  border: none;
  background: #fff;
  width: 286px;
  margin-bottom: 16px;
  padding: 10px 20px;
  outline: none;
}

.footer-socials .socials a:hover,
footer .footer-socials .socials a:active {
  color: var(--orange)
}

.footer-socials svg {
  font-size: 2em;
} */

/*Button*/
.btn {
  border-radius: .75em;
  /* font-size: 1rem !important; */
}

.btn.btn-round {
  border-radius: 1rem;
  /* font-size: 1rem !important; */
}

/* .btn:not(.btn-sm) {
  padding: 0.5em;
} */

.btn-primary {
  color: #fff;
  background: #f07d04;
  border-color: #f07d04;
  box-shadow: 0 1px 10px rgb(0 0 0 / 16%);
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background: var(--blue);
  border-color: var(--blue);
  /* background: #fc9f2d;
  border-color: #fc9f2d; */
}

.btn-primary:active {
  color: #fff;
  background: #c96100;
  border-color: #c96100;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background: #c96100;
  border-color: #c96100;
}

.btn.btn-login {
  font-size: 1em;
}

.bullet-wrap .bullet {
  width: 0.5em;
  height: 0.5em;
  margin-top: 0;
}

/*Form*/

.body-section {
  margin: 60px 0;
  min-height: calc(100vh - 650px);
}

/* .page-header {
  text-align: center;
  background: none;
  font-size: 2em;
  font-weight: bold;
  padding-top: 0;
  margin: 0 -20px;
} */

.body-2-text,
.purchase-page ol.breadcrumb li a {
  font-size: 1em;
  line-height: 1.5;
}

.custom-control-label::before {
  top: .125rem;
}

.custom-form .custom-checkbox .custom-control-label::after {
  top: .125rem;
}

.custom-form .form-group,
.profile-page .custom-form .input-group {
  margin-bottom: 1.5em;
}

.custom-form .form-group .text-danger,
.custom-form .form-group .helper {
  margin-top: 0.25em;
  font-size: .625em;
  line-height: 1.25em;
}

.profile-page .content-section .custom-form .card.dns-card {
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 25%);
}

.profile-page .btn {
  font-size: 1em;
}

.card.dns-card {
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 25%);
}

.card.card-border-top {
  border-top: 4px solid #fc9f2d !important;
}

.card.dns-card .card-body {
  padding: 1em;
}

.card.dns-card .card-body h4 {
  font-size: 1.4em;
  font-weight: 600;
}

.manage-domain h4,
.payment-page h4 {
  font-size: 1.4em;
}

.manage-domain h6 {
  font-size: 1.2em;
}

.card.dns-card .card-footer {
  background: none;
}

.custom-form .form-group>.form-control,
.custom-form .form-control {
  border-radius: 0.75em;
  border-color: #014282;
  padding: 0.475rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
}

.custom-form .form-group>.float-label {
  font-size: 0.825em !important;
}

.custom-form .block-title {
  color: #014282;
  font-size: 1.25em;
  font-weight: 500;
  border-bottom: 2px solid #014282;
  padding: 0.65em 0;
  margin-bottom: 30px;
}

.custom-form .form-group>.float-label,
.custom-form .input-group>.float-label {
  padding: 18px 20px;
}

@media (max-width: 1919.9px) {

  .custom-form .form-group>.float-label,
  .custom-form .input-group>.float-label {
    padding: 8px 20px;
  }
}

.custom-control-label {
  font-weight: 500;
}

/*File upload*/
.upload-info h6 {
  font-size: 1em;
}

.custom-form .custom-file-label {
  background-color: var(--orange);
  border-radius: 0.75em;
  height: auto;
}

.dm-uploader {
  border: 3px dashed #014282 !important;
  border-radius: 10px;
  padding: 0 !important;
  position: relative;
  padding-bottom: 35px;
}

.dm-uploader .upload-head {
  background: linear-gradient(90deg, #007dc5 0%, #004282 100%);
  width: calc(100% + 6px);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px 20px;
  border-radius: 10px;
  position: absolute;
  top: -4px;
  left: -3px;
  right: -3px;
}

.dm-uploader .upload-icon {
  margin-top: 35px;
}

.dm-uploader .upload-head .dots {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #fff;
}

.dm-uploader .download-section p {
  font-size: 1em;
}

.bg-green-light {
  background-color: rgba(84, 230, 0, 0.3);
}

.text-orange {
  color: var(--orange) !important;
}

.text-blue {
  color: var(--blue) !important;
}

.badge-success {
  color: var(--green);
  background-color: rgba(84, 230, 0, 0.3);
}

.badge-primary{
  background-color: var(--orange);
}

.page-thank .body {
  display: flex;
  flex-flow: column;
}

.page-thank .body>* {
  margin-top: 15px;
}

.page-thank img {
  width: 450px;
}

@media (max-width: 559.9px) {
  .page-thank img {
    width: 350px;
  }
}

.domain-search-page .prefix.icon-search {
  transform: rotate(0);
}

.domain-search-page .search-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5em;
  /* height: 71px; */
}

.domain-search-page .search-input {
  width: 100%;
  margin-right: 1em;
}

.domain-search-page .group-search {
  /* margin-right: 1em; */
  box-shadow: 1px 1px 30px rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 20px;
}

.domain-search-page .group-search * {
  background-color: #fff;
}

.domain-search-page .search-action {
  margin-top: 1em;
  height: inherit;
}

.domain-search-page .btn-search {
  height: 100%;
  padding: 0.375rem calc(0.75rem + 20px);
}

/* .domain-search-page .btn-purchase{
  width: 192px;
  height: 78px;
} */

/* @media (min-width: 576px) {
  .domain-search-page .btn-search{  
    width: 142px;
    height: 100%;
    padding: 0.375rem calc(0.75rem + 40px);  
  }
  
  .domain-search-page .btn-purchase{
    width: 54px;
    height: 22px;
  }
} */

/* @media (min-width: 768px) {
  .domain-search-page .btn-search{  
    width: 142px;
    height: 100%;
    padding: 0.375rem calc(0.75rem + 40px);  
  }
  
  .domain-search-page .btn-purchase{
    width: 54px;
    height: 22px;
  }
} */

.domain-search-page .domain-result .btn-purchase.btn-green {
  background-color: rgba(84, 230, 0, 0.3);
  color: var(--green);
  border: none;
}

.purchase-wizard .custom-form .custom-file-label {
  line-height: 1.7;
}

.domain-search-page .btn-purchase,
.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-outline-primary,
.purchase-wizard .custom-form .custom-file-label {
  height: 70px;
  font-weight: 600;
}

.domain-search-page .btn-purchase{
  font-weight: 500;
}

a.btn.btn-primary,
a.btn.btn-outline-primary {
  line-height: 2;
}

.suggested-domain-item{
  font-weight: 500;
  font-size: 1.1em;
}

.domain-search-page .domain-result .search-text {
  margin-right: 5px;
  font-weight: 600;
  font-size: 1.1em;
}

.domain-search-page .domain-result .domain-price {
  font-weight: 600;
  font-size: 1.1em;
}

@media (max-width: 1919.9px) {
  .domain-search-page .btn-purchase,
  .btn.btn-primary,
  .btn.btn-secondary,
  .btn.btn-outline-primary,
  .purchase-wizard .custom-form .custom-file-label {
    height: 50px;
    font-size: 20px;    
  }

  .domain-search-page .btn-purchase,
  .domain-search-page p{
    font-size: 20px;
  }

  .suggested-domain-item,
  .domain-search-page .domain-result .search-text,
  .domain-search-page .domain-result .domain-price{
    font-size: 22px;
  }

  .domain-search-page .btn-purchase img{
    width: 25%;
  }
}

@media (max-width: 1279.9px) {
  .domain-search-page .btn-purchase,
  .btn.btn-primary,
  .btn.btn-secondary,
  .btn.btn-outline-primary,
  .purchase-wizard .custom-form .custom-file-label {
    height: auto;
    font-size: inherit;
  }

  .suggested-domain-item,
  .domain-search-page .domain-result .search-text,
  .domain-search-page .domain-result .domain-price{
    font-size: 16.5px;
  }

  a.btn.btn-primary,
  a.btn.btn-outline-primary,
  .purchase-wizard .custom-form .custom-file-label {
    line-height: 1.5;
  }
}

@media (max-width: 559.9px) {
  .domain-search-page .btn-purchase {
    font-size: 80%;
    padding-left: 0;
    padding-right: 0;
  }

  .suggested-domain-item,
  .domain-search-page .domain-result .search-text,
  .domain-search-page .domain-result .domain-price{
    font-size: inherit;
  }

  .domain-search-page .btn-purchase img {
    width: 25%;
  }
}

.domain-search-page .domain-result {
  color: var(--green);
  display: flex;
  align-items: center;
}

.domain-search-page .domain-result-available,
.domain-search-page .domain-result-available-purchase {
  display: flex;
  align-items: center;
}

.domain-search-page .domain-result-available-purchase {
  justify-content: end;
}

@media (max-width: 599.9px) {
  /* .domain-search-page .domain-result-available-purchase {
    display: flex;
    align-items: end;
    flex-flow: column;
  } */

  /* .domain-search-page .domain-result-available-purchase span{
    font-size: 80% !important;
  } */
}

.domain-search-page .domain-result .badge {
  font-size: 0.625em;
  font-weight: 400;
  padding: 0.4em;
}

.domain-search-page .description-icon svg {
  height: 4em;
}

.domain-search-page .description-title {
  line-height: 1.5em;
  font-weight: 600;
  color: var(--blue);
  font-size: 1.25em;
}

.domain-search-page .description-text {
  font-size: 0.825em;
  line-height: 1.5em;
}

.domain-search-page .suggest-text {
  font-size: 1.5em;
  font-weight: 700;
  color: var(--blue);
}

.domain-whoIs .search-term {
  font-size: 1.5em;
  font-weight: 400;
}

.domain-whoIs .card-whoIs-header,
.domain-whoIs .card-whoIs-label {
  color: var(--blue);
  line-height: 1.5;
}

.domain-whoIs .card-whoIs-header {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5em;
  margin-bottom: 15px;
}

.domain-whoIs .card-whoIs-header {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5em;
  margin-bottom: 15px;
}

.domain-list .table thead tr th {
  font-size: 1.1em;
  border: 1px solid white;
  word-break: normal;
}

.dashboard table thead tr th {
  font-weight: 500;
  color: var(--blue);
}

.dashboard table tbody tr td:nth-child(2),
.dashboard table tbody tr td:not(:nth-child(2)) {
  font-size: inherit;
}

.dashboard table thead tr th:nth-child(3) {
  width: 325px;
}

.dashboard table thead tr th:nth-child(4) {
  width: 210px;
}

.dashboard table thead tr th:nth-child(1),
.dashboard table tbody tr td:nth-child(1) {
  border-right: 0;
}

.dashboard table tbody tr td .btn {
  font-size: 1em;
}

.domain-list .table tbody tr {
  border-bottom: 2px solid #E3E3E3;
  box-shadow: 0 2px 4px -2px rgb(0 0 0 / 15%);
}

.domain-list .table tr td {
  border-left: 0;
  border-right: 0;
}

.domain-list table tbody tr td {
  font-size: 1em !important;
}


.domain-list table tbody tr td:nth-child(2) {
  font-size: 1.1em !important;
}

@media (max-width: 959.9px) {
  .dashboard table tbody tr td:nth-child(3),
  .dashboard table tbody tr td:nth-child(4),
  .dashboard table thead tr th:nth-child(3),
  .dashboard table thead tr th:nth-child(4) {
    width: 130px;
  }
}

@media (max-width: 599.9px) {
  .dashboard table tbody tr td:nth-child(3),
  .dashboard table tbody tr td:nth-child(4),
  .dashboard table thead tr th:nth-child(3),
  .dashboard table thead tr th:nth-child(4) {
    width: 100px;
  }
}

.domain-list table tbody tr td:nth-child(4) .btn {
  padding-top: 0;
  padding-bottom: 0;
}

/* .domain-list table tbody tr td:nth-child(4) .btn>svg {
  color: red;
  height: 0.825em;
} */

.domain-list table tbody tr td:nth-child(1),
.domain-list table thead tr th:nth-child(1) {
  padding-left: 0;
  text-align: left;
  max-width: 40px;
  white-space: nowrap;
}

.table-head-text {
  margin-top: 1em;
  font-weight: 700;
  font-size: 1.6em;
  color: var(--blue);
  padding-bottom: 25px;
  border-bottom: 2px solid rgb(0 0 0 / 25%);
  box-shadow: 0 4px 4px -3px rgb(0 0 0 / 25%);
}

@media (max-width: 559.9px) {

  .table th,
  .table td {
    padding: 0.25rem;
  }
}

.my-domain-page table tbody tr td {
  padding-top: 21px;
  padding-bottom: 21px;
}

.purchase-wizard .content-section {
  padding-top: 0;
}

.purchase-wizard h4,
.purchase-wizard .fa.fa-download {
  color: var(--blue) !important;
}

.purchase-wizard .card-footer {
  border-top: none !important;
}

.purchase-wizard .wizard-navigation {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: end;
}

.purchase-wizard .wizard-navigation .btn {
  font-size: 1em;
}

.btn.btn-skip {
  margin-left: 0.5em;
  font-size: 0.5em;
  font-weight: 600;
  color: var(--blue);
}

.btn.btn-next,
.btn.btn-prev {
  border-radius: 50%;
  font-size: 0.5em;
  padding: 0;
}

.btn.btn-next {
  color: var(--white);
  background-color: var(--orange);
  border: 1px solid var(--orange);
}

.btn.btn-prev {
  color: gray;
  background-color: var(--white);
  border: 1px solid gray;
}

@media (max-width: 559.9px) {
  .border-sm-left-0 {
    border-left: none !important;
  }

  .border-sm-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
}

.document-icon {
  color: var(--orange)
}

/* Name Server hover */
.name-server-block .dropdown-menu {
  box-shadow: 0px 0px 5px -1px var(--orange);
  min-width: 400px;
  width: 600px;
}

@media (max-width: 559.9px) {
  .name-server-block .dropdown-menu {
    width: 350px;
  }
}

.name-server-block .dropdown-menu svg {
  font-size: 1.25em;
}

.name-server-block .arrow::before {
  border-right-color: var(--orange) !important;
}

/* .name-server-block .dropdown-menu .close {
  position: absolute;
  right: 15px;
  top: 5px;
  color: var(--orange);
} */

.name-server-block h6 {
  font-size: 1.25em;
}

.name-server-block .dropdown-menu>p,
.name-server-block .dropdown-menu li {
  font-size: 0.825em;
  line-height: 1.5em;
}

.name-server-block .dropdown-menu ul {
  list-style: disc;
  padding-top: 25px;
  padding-left: 25px;
}

.name-server-block .dropdown-menu ul>li {
  list-style: disc;
}

.caption-text,
.summary-section .card .content .property .field,
.summary-section .card .content .property .value {
  font-size: 0.825em;
  line-height: 1.5;
}

.caption-3-text {
  font-size: .75em;
}

.summary-section .card .content .property .field {
  word-break: normal;
}

.border-primary,
.btn-group-right .btn,
.admin-page .content .main-panel .nav-group-right .btn-dropdown {
  border-color: var(--orange) !important;
}

.rounded-lg {
  border-radius: 1em !important;
}

.purchase-success .card {
  box-shadow: 0px 0px 10px 1px #cecece;
  border: none;
  border-radius: 15px;
}

.purchase-success .success-text {
  line-height: 1.5em;
}

.home-page .faq-container .faq .faq-item .faq-title{
  color: inherit;
}