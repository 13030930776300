/* [class*="col-"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
} */

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#files {
    overflow-y: scroll !important;
    min-height: 320px;
}

#debug {
    overflow-y: scroll !important;
    height: 180px;
}

.dm-uploader {
    border: 0.25rem dashed #A5A5C7;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .dm-uploader.active {
        border-color: red;
        border-style: solid;
    }
/* Server Info*/
.name-server-block .arrow {
	position: absolute;
	display: block;
	margin: auto 0;
	left: calc(-.5rem - 1px);
	top: 0;
	bottom: 0;
	width: .5rem;
	height: 1rem;
}
.name-server-block .arrow::after, .name-server-block .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.name-server-block .arrow::before {
				left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #16347b;
}
.name-server-block .arrow-top {
    position: relative;
}
.name-server-block .arrow-top::after {
	display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.5em solid transparent;
    border-bottom: 0.5em solid #16347b;
    border-left: 0.5em solid transparent;
    position: absolute;
    right: 138px;
    top: -25px;
}
.name-server-block .btn-group:hover .dropdown-menu.drop-mobile.show {
	transform: translate3d(-209px, 38px, 0px)!important;
}
.name-server-block .btn:hover ~ .dropdown-menu .close {
    opacity: 0;
}
@media (min-width: 768px) {
    .name-server-block .btn-group:hover .dropdown-menu {
	display: block;
}
    .name-server-block .dropdown-menu-right {
        top: 0px;
        left: 0px;
        will-change: transform;
        transform: translate3d(50px, -45%, 0px) !important;
    }
}
@media (max-width: 767px) {
    .name-server-block .arrow-top::after {
        right: -91px;
    }
    .name-server-block .dropdown-menu {
        min-width: 350px !important;
    }
}
.summary-section .card .content .property .field {
    width: 60%;
}
/**override main.css*/
.invalid-feedback-override {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fe2a2e;
}