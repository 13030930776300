@import '../../../v2/scss/core';

.info-message {
    &-icon {
        margin-left: 20px;
        margin-right: 60px;

        img {
            max-width: unset;
        }
    }
}

@include layout-bp('lt-xl') {
    .info-message {
        &-icon {
            margin: 0 30px;

            img {
                height: 68px;
            }
        }
    }
}
