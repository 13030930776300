.name-server-block .arrow {
    position: absolute;
    display: block;
    margin: auto 0;
    left: calc(-.5rem - 1px);
    top: 0;
    bottom: 0;
    width: .5rem;
    height: 1rem;
}

.name-server-block .arrow::after,
.name-server-block .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.name-server-block .arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #16347b;
}

.name-server-block .arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #16347b;
}

.name-server-block .btn-group:hover .dropdown-menu {
    display: block;
}

@media (min-width: 768px) {
    .name-server-block .btn-group:hover .dropdown-menu {
        display: block;
    }

    .name-server-block .dropdown-menu-right {
        top: 0px;
        left: 0px;
        will-change: transform;
        transform: translate3d(50px, -45%, 0px) !important;
    }
}

@media (max-width: 767px) {
    .name-server-block .arrow-top::after {
        right: -91px;
    }

    .name-server-block .dropdown-menu {
        min-width: 350px !important;
    }
}