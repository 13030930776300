@import 'src/v2/scss/core';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: normal;
    word-break: break-word;
}

html,
body {
    font-family: $kantumruy;
    font-size: $p;
    color: $text;
    font-weight: $medium;

    &.noscroll {
        overflow: hidden;
        max-height: 100vh;
        height: 100vh;
    }

    @include layout-bp('lt-xl') {
        font-size: $s15 !important;
    }
}

body {
    background: #fff;
}

.container {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
}

.container-1740 {
    max-width: 1360px;
    margin: 0 auto;
    width: 100%;
}

.container-1200 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.container-1250 {
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
}

button {
    cursor: pointer;
    border: none;
    background: none;
    outline: none !important;

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.button {
    &:hover {
        text-decoration: none;
    }

    &.primary {
        background: $orange;

        &:hover,
        &:active,
        &.active {
            background: $blue;
        }
    }
}

.flex {
    &-row {
        margin: 0 -15px;
    }

    &-column {
        margin: 0 15px;
        flex: 0 0 auto;
    }
}

.link {
    span {
        border-bottom: 2px solid transparent;

        &:hover,
        &:active,
        &.active {
            border-bottom: 2px solid $orange;
        }
    }
}

.orange {
    color: $orange;
}

.page-container {
    &.home-header-bg {
        background: url('../../../assets/svg/home-header-bg.svg') no-repeat top center;
        background-size: 1920px auto;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    margin: 0;
}

h1 {
    font-size: $s64;
}

h2 {
    font-size: $s55;
}

h3 {
    font-size: $s52;
}

h4 {
    font-size: $s40;
}

h5 {
    font-size: $s30;
}

h6 {
    font-size: $s28;
}

p {
    font-size: $p;
    margin: 0;
}

.small {
    font-size: $small;
}

.pp52 {
    font-size: $pp52;
}

.pp30 {
    font-size: $pp30;
}

.title-h2 {
    color: $blue;
    font-weight: $bold;
    text-align: center;
    margin-bottom: 40px;
}

.title-h5 {
    font-weight: $semibold;
    text-align: center;
}

.poppins {
    font-family: $poppins;
}

.blue-bg {
    background: $blue;
    margin-top: -20px;
    height: 20px;
    padding: 0 !important;
}

main {
    padding-top: 80px;
    padding-bottom: 60px;

    &.miniApp {
        padding-bottom: 0 !important;
    }

    @include layout-bp('lt-lg') {
        padding-top: 52px;
    }
}

img {
    max-width: 100%;
}

@import './layout-lg';
@import './layout-sm';
@import './layout-xs';
